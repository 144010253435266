<template>
  <div>
    <h2>応募履歴</h2>
    <b-card
      title=""
    >
      <BasicTable
        :value="testdata"
        :header="fields"
      />
    </b-card>
  </div>
</template>

<script>
import {
  // BRow, BCol, BButton,
  BCard,
  //  BCardText,
} from 'bootstrap-vue'
import BasicTable from '@/components/ui/table/BasicTable.vue'
import testdata from '@/components/company/applyHistory/test/求人企業_応募履歴.json'
import fields from '@/components/conf/JPCAREER様_学習PF_求人企業_応募履歴.json'

export default {
  components: {
    // BRow,
    // BCol,
    // BButton,
    BCard,
    // BCardText,
    BasicTable,
  },
  directives: {
  },
  props: {
    profileDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      testdata,
      fields,
    }
  },
  methods: {
    edit() {
    },
  },
}
</script>
